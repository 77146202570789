






















import { Component, Vue, Watch } from 'vue-property-decorator'
import TableBase from '@/components/atoms/TableBase1110.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import moment from 'moment'

@Component({
  components: {
    TableBase,
    InputWithLabel,
    ButtonBase,
    SelectBase
  },
})
export default class Notification extends Vue {

  private service = Vue.prototype.$cookies.get('dataGdls').service

  private title = ''

  private currentPage = 1

  private count = 0

  private notificationTableBasePerPage = 30

  private notificationTableBaseItems: object[] = []

  private notificationTableBaseFields = [
    { key: 'target', label: '対象' },
    { key: 'title', label: '件名' },
    { key: 'showFlag', label: '表示有無' },
    { key: 'showStartDate', label: '表示開始日時' },
    { key: 'showEndDate', label: '表示終了日時' },
    { key: 'registerDate', label: '登録日時' },
    { key: 'updateDate', label: '更新日時' },
    { key: 'delete', label: '削除' },
  ]

  private notificationTableBaseLinks = ['title']

  private notificationTableBaseButtons = ['delete']

  private notificationTableBaseToggles = ['showFlag']

  // showFlagのonChangeファンクション
  private switchShowFlag(id: number, isDisplaying: boolean): { func: Function; funcParams: {} } {
    return {
      func: this.updateNotification,
      funcParams: {
        id: id,
        isDisplaying: !isDisplaying,
      },
    }
  }

  /**
   * お知らせ一覧を取得する
   */
  private async loadNotifications() {
    const params = [
      `limit=${this.notificationTableBasePerPage}`,
      `offset=${this.notificationTableBasePerPage * (this.currentPage - 1)}`,
    ]

    this.service === 'quiz' && params.push(`code=qz`)
    if (this.title) params.push(`title=${this.title}`)
    await Vue.prototype.$http.httpWithToken.get(`/notifications?${params.join('&')}`).then((res: any) => {
      this.notificationTableBaseItems = res.data.notifications.map(
        (notification: {
          id: number
          roles: string[]
          title: string
          isDisplaying: boolean
          startedAt: Date
          endedAt: Date
          createdAt: Date
          updatedAt: Date
        }) => {
          return {
            target: notification.roles.length === 4 ? '全ユーザー' : notification.roles.join('/'),
            title: { url: `/admin/notification-edit/${notification.id}`, name: notification.title },
            showFlag: {
              checked: notification.isDisplaying,
              on: '',
              off: '',
              id: `showFlag-${notification.id}`,
              onChange: this.switchShowFlag(notification.id, notification.isDisplaying),
            },
            showStartDate: moment(notification.startedAt).format('YYYY/MM/DD HH:mm'),
            showEndDate: notification.endedAt ? moment(notification.endedAt).format('YYYY/MM/DD HH:mm') : '',
            registerDate: moment(notification.createdAt).format('YYYY/MM/DD HH:mm'),
            updateDate: moment(notification.updatedAt).format('YYYY/MM/DD HH:mm'),
            delete: [{ onclick: this.destroyNotification, variable: notification.id, name: '削除' }],
          }
        }
      )
      this.count = res.data.count
    })
  }

  /**
   * お知らせを更新する（当画面では表示有無フラグのみ）
   */
  private async updateNotification(params: { id: number; isDisplaying: boolean }) {
    await Vue.prototype.$http.httpWithToken
      .patch(`/notifications/${params.id}`, {
        isDisplaying: params.isDisplaying,
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新します。')
          this.loadNotifications()
        }
      })
  }

  /**
   * お知らせを削除する
   */
  private async destroyNotification(id: number) {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/notifications/${id}`)
      .then(() => {
        // お知らせを削除したことで現在のページのお知らせがなくなる場合は1ページ前を表示する
        if (this.notificationTableBaseItems.length === 1 && this.currentPage > 1)
          this.currentPage = this.currentPage - 1
        this.loadNotifications()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadNotifications()
  }

  private async mounted() {
    this.loadNotifications()
  }
}
